import React from 'react';
import styled from "styled-components";
import {graphql, Link} from "gatsby";
import Header from "./header";
import Footer from "./footer";
import BackArrow from "../images/backArrow.svg";
import Decoration from "../images/decoration.svg";
import Seo from "./seo";

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 1110px;
  align-self: center;
  margin-bottom: 52px;
  position: relative;
  flex: 1 1 auto;
  @media screen and (max-width: 1100px) {
    width: 1000px;
  }
  @media screen and (max-width: 960px) {
    width: 850px;
  }
  @media screen and (max-width: 800px) {
    width: 700px;
  }
`;

const Title = styled.div`
  font-family: Montserrat;
  font-weight: 500;
  font-size: 24px;
  color: #103072;
  margin-bottom: 30px;
  @media screen and (max-width: 1100px) {
    font-size: 20px;
  }
  @media screen and (max-width: 960px) {
    font-size: 16px;
  }
  @media screen and (max-width: 800px) {
    font-size: 12px;
  }
`;

const Text = styled.div`
  font-family: Montserrat;
  font-size: 16px;
  line-height: 22px;
  color: #212939;
  max-width: 730px;
  white-space: break-spaces;
  margin-bottom: 56px;
  @media screen and (max-width: 1100px) {
    font-size: 13px;
    line-height: 18px;
  }
  @media screen and (max-width: 960px) {
    font-size: 11px;
    line-height: 15px;
  }
  @media screen and (max-width: 800px) {
    font-size: 9px;
    line-height: 12px;
  }
`;

const Subtitle = styled(Text)`
  margin-bottom: 16px;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Invitation = styled(Title)`
  width: 255px;
  margin-top: 22px;
  @media screen and (max-width: 1100px) {
    width: 225px;
    margin-top: 17px;
  }
  @media screen and (max-width: 960px) {
    width: 205px;
    margin-top: 14px;
  }
  @media screen and (max-width: 800px) {
    width: 175px;
    margin-top: 10px;
  }
`;

const OwnLink = styled(Link)`
  text-decoration: none;
  display: flex;
  flex-direction: row;
  margin-bottom: 50px;
  width: fit-content;
  @media screen and (max-width: 1100px) {
    margin-bottom: 40px;
  }
  @media screen and (max-width: 960px) {
    margin-bottom: 30px;
  }
  @media screen and (max-width: 800px) {
    margin-bottom: 20px;
  }
`;

const SingleVacancy = ({data, pageContext}) => {
    console.log("Page:", pageContext)

    const ReturnImages = () => {
        return (
            <>
                <Image src={Decoration} alt="decor"/>
            </>
        )
    }

    const Image = styled.img`
      position: absolute;
      left: -460px;
      top: 130px;
    `;

    return (
        <MainContainer>
            <Seo title={pageContext.currentValue.seo_title}
                 description={pageContext.currentValue.seo_description}
                 keywords={pageContext.currentValue.seo_keywords.split(',')}
            />
            <Header/>
            <Container>
                <ReturnImages/>
                <OwnLink
                    to="/vacancies">
                    <img style={{marginRight: "16px"}} src={BackArrow} alt="backArrow"/>
                    <Text style={{marginBottom: "0"}}>
                        Назад к списку вакансий
                    </Text>
                </OwnLink>
                <Title>
                    {data.strapiVacancies.title}
                </Title>
                <RowContainer>
                    <div>
                        <Subtitle>
                            Обязанности:
                        </Subtitle>
                        <Text>
                            {data.strapiVacancies.responsibilities}
                        </Text>
                        <Subtitle>
                            Требования:
                        </Subtitle>
                        <Text>
                            {data.strapiVacancies.requirements}
                        </Text>
                        <Subtitle>
                            Условия:
                        </Subtitle>
                        <Text>
                            {data.strapiVacancies.conditions}
                        </Text>
                    </div>
                    <Invitation>
                        Хотите стать частью команды? Просто напишите на <br/>
                        <strong><a style={{color: "inherit", textDecoration: "none"}} href="mailto:pps@pp-proekt.ru">pps@pp-proekt.ru</a></strong><br/>
                        о себе и приложите резюме
                    </Invitation>
                </RowContainer>
            </Container>
            <Footer/>
        </MainContainer>
    )
}

export default SingleVacancy;

export const query = graphql`
query($strapiId: Int){
  strapiVacancies(strapiId: {eq: $strapiId}){
    title
    responsibilities
    requirements
    conditions
    strapiId
  }
}
`;